.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}


@keyframes ldio-3vi3igwauhw {
    0% { transform: rotate(0deg) }
   50% { transform: rotate(22.5deg) }
  100% { transform: rotate(45deg) }
}

.center-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  margin-bottom: 35rem;
}

.ldio-3vi3igwauhw > div {
  transform-origin: 97px 97px;
  animation: ldio-3vi3igwauhw 0.2247191011235955s infinite linear;
}
.ldio-3vi3igwauhw > div div {
    position: absolute;
    width: 21.34px;
    height: 143.56px;
    background: #454545;
    left: 97px;
    top: 97px;
    transform: translate(-50%,-50%);
}
.ldio-3vi3igwauhw > div div:nth-child(1) {
    width: 116.39999999999999px;
    height: 116.39999999999999px;
    border-radius: 50%;
}
.ldio-3vi3igwauhw > div div:nth-child(6) {
    width: 77.6px;
    height: 77.6px;
    background: #ffffff;
    border-radius: 50%;
}.ldio-3vi3igwauhw > div div:nth-child(3) {
  transform: translate(-50%,-50%) rotate(45deg)
}.ldio-3vi3igwauhw > div div:nth-child(4) {
  transform: translate(-50%,-50%) rotate(90deg)
}.ldio-3vi3igwauhw > div div:nth-child(5) {
  transform: translate(-50%,-50%) rotate(135deg)
}
.loadingio-spinner-gear-3eru7zplu16 {
  width: 194px;
  height: 194px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-3vi3igwauhw {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-3vi3igwauhw div { box-sizing: content-box; }
/* generated by https://loading.io/ */
