.main-header {
  width: 90%;
  padding: 0 5%;
  height: 6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.73);
}

@media only screen and (min-width: 990px) {
  .main-header {
    width: 70%;
    padding: 0 15%;
  }
}

