.side-drawer {
  position: fixed;
  right: 0;
  padding-right: 1rem;
  top: 10vh;
  z-index: 100;
  background: #454545;
}

@media only screen and (min-width: 990px) {
  .side-drawer {
    display: none;
  }
}