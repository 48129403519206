@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Prompt:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Prompt:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Prompt:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slide-in-down-enter {
  -webkit-transform: translateY(-15%);
          transform: translateY(-15%);
}

.slide-in-down-enter-active {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  transition: all 300ms;
}

.slide-in-down-exit {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  opacity: 1;
}

.slide-in-down-exit-active {
  -webkit-transform: translateY(-10%);
          transform: translateY(-10%);
  opacity: 0;
  transition: all 300ms;
}


.slide-in-right-enter {
  -webkit-transform: translateX(15%);
          transform: translateX(15%);
}

.slide-in-right-enter-active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-right-exit {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
}

.slide-in-right-exit-active {
  -webkit-transform: translateX(15%);
          transform: translateX(15%);
  opacity: 0;
  transition: all 200ms;
}

.modal-transition-enter {
  -webkit-transform: translateY(-10rem);
          transform: translateY(-10rem);
  opacity: 0;
}

.modal-transition-enter-active {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  transition: all 300ms;
}

.modal-transition-exit {
  -webkit-transform:translateY(0);
          transform:translateY(0);
  opacity: 1;
}

.modal-transition-exit-active {
  -webkit-transform: translateY(-10rem);
          transform: translateY(-10rem);
  opacity: 0;
  transition: all 300ms;
}

.post-transition-enter {
  opacity: 0;
  -webkit-transform:scaleY(0);
          transform:scaleY(0);
  max-height: 0px;
}

.post-transition-enter-active {
  opacity: 1;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  max-height: 100%;
  transition: all 200ms;
}

.post-transition-exit {
  opacity: 1;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  max-height: 100%
}

.post-transition-exit-active {
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  opacity: 0;
  max-height: 0px;
  transition: all 200ms;
}

.slide-down-enter {
  opacity: 0;
  max-height: 0px;
}

.slide-down-enter-active {
  opacity: 1;
  height: auto;
  max-height: 600px;
  transition: all 300ms;
}

.slide-down-exit {
  opacity: 1;
  max-height: 600px;
}

.slide-down-exit-active {
  opacity: 0;
  overflow: hidden;
  max-height: 0px;
  transition: all 300ms;
}


@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translateX(-10rem);
            transform: translateX(-10rem);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}


@keyframes slideInRight {
  from {
    -webkit-transform: translateX(-10rem);
            transform: translateX(-10rem);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translateX(10rem);
            transform: translateX(10rem);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translateX(10rem);
            transform: translateX(10rem);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Footer_footer__35Kcv {
  opacity: 1;
  text-align: center;
  margin-top: 50px;
  width: 100%;
  padding: 1rem 0;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  font-family: 'Montserrat', sans-serif;
}
.MainHeader_main-header__1_9vD {
  width: 90%;
  padding: 0 5%;
  height: 6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.73);
}

@media only screen and (min-width: 990px) {
  .MainHeader_main-header__1_9vD {
    width: 70%;
    padding: 0 15%;
  }
}


.NavItem_active-link__2vQ1Y {
  border-bottom: 1pt #f7e0ad solid !important;
  color: #f7e0ad !important;
}
.NavLinks_nav-links__3LEXZ {
  display: none;
}

@media only screen and (min-width: 990px) {
  .NavLinks_nav-links__3LEXZ {
    list-style: none;
    padding-left: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .NavLinks_nav-links_auth__2gvGZ a {
    background-color: transparent !important;
    color: white !important;
    border: 1pt solid white !important;
    padding: 0 0.25rem !important;
  }

  .NavLinks_nav-links_auth__2gvGZ a:hover,
  .NavLinks_nav-links_auth__2gvGZ a:active {
    color: #f7e0ad !important;
    border: 1pt solid #f7e0ad !important;
  }

  .NavLinks_nav-links__3LEXZ a {
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    margin: 0 0.5rem;
    padding: 0;
    color: white;
    font-size: 15px;
    display: flex;
    justify-content: center;
    text-decoration: none;
  }

  .NavLinks_nav-links__3LEXZ a:hover {
    color: #f7e0ad;
  }

  .NavLinks_nav-links__3LEXZ button {
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    border: 1pt solid white;
    color: white;
    margin-left: 0.5rem;
    font-size: 15px;
    background-color: transparent;
    padding: 0 0.25rem;
    text-align: center;
  }

  .NavLinks_nav-links__3LEXZ button:hover {
    cursor: pointer;
    border: solid 1pt #f7e0ad;
    color: #f7e0ad;
  }
}

.MainNavigation_menu-btn__NuUNW {
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
}

.MainNavigation_menu-btn__NuUNW span {
  display: block;
  width: inherit;
  height: 2.5px;
  background: white;
}

.MainNavigation_main-title__p93rJ {
  text-decoration: none;
  color: #f7e0ad;
  font-family: "Prompt", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.25rem;
}

.MainNavigation_main-title__p93rJ:hover {
  color: white;
}

.MainNavigation_main-nav__jtnTe {
  display: none;
}

@media only screen and (min-width: 370px) {
  .MainNavigation_menu-btn__NuUNW {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media only screen and (min-width: 990px) {
  .MainNavigation_menu-btn__NuUNW {
    display: none;
  }

  .MainNavigation_main-nav__jtnTe {
    display: block;
    padding-left: 0;
  }
}

.SideDrawer_side-drawer__3vVd_ {
  position: fixed;
  right: 0;
  padding-right: 1rem;
  top: 10vh;
  z-index: 100;
  background: #454545;
}

@media only screen and (min-width: 990px) {
  .SideDrawer_side-drawer__3vVd_ {
    display: none;
  }
}
.Backdrop_backdrop__2U85w {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: transparent;
  z-index: 10;
}

.DrawerNavItem_active-link__26FB4 {
  color: #f7e0ad !important;
  border-bottom: #f7e0ad solid 1px;
}

.DrawerNavItem_drawer-nav-item__2s2Nj:hover {
  color: #f7e0ad !important
}
.DrawerNavLinks_drawer-nav-links__3sald {
  list-style: none;
  margin: 2rem 1rem;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  grid-row-gap: 20px;
  row-gap: 20px;
  align-items: center;
}

.DrawerNavLinks_drawer-nav-links__3sald a {
  margin: 2rem 0;
  color: white;
  font-size: 25px;
  padding: 0.25rem;
  text-decoration: none;
}

.DrawerNavLinks_drawer-nav-links__3sald a:hover {
  color: #f7e0ad;
}

.DrawerNavLinks_drawer-nav-links-auth__FSs38 {
  background-color: #f7e0ad;
  color: #454545 !important;
  padding: 5px 8px;
}

.DrawerNavLinks_drawer-nav-links-auth__FSs38:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.DrawerNavLinks_drawer-nav-links-auth__FSs38 a {
  color: #454545;
}

.DrawerNavLinks_drawer-nav-links-auth__FSs38 a:hover {
  color: #454545;
}

.DrawerNavLinks_drawer-nav-links__3sald button {
  background-color: #f7e0ad;
  border: none;
  font-size: 25px;
  color: #454545;
  padding: 5px 8px;
}

.DrawerNavLinks_drawer-nav-links__3sald button:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.DrawerNavLinks_drawer-nav-links__3sald a:hover
.DrawerNavLinks_drawer-nav-links__3sald a:active,
.DrawerNavLinks_active__11nzs {
  color: #f7e0ad;
}
.LoadingSpinner_loading-spinner__overlay__Nj7Ui {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}


@-webkit-keyframes LoadingSpinner_ldio-3vi3igwauhw__80mjp {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg) }
   50% { -webkit-transform: rotate(22.5deg); transform: rotate(22.5deg) }
  100% { -webkit-transform: rotate(45deg); transform: rotate(45deg) }
}


@keyframes LoadingSpinner_ldio-3vi3igwauhw__80mjp {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg) }
   50% { -webkit-transform: rotate(22.5deg); transform: rotate(22.5deg) }
  100% { -webkit-transform: rotate(45deg); transform: rotate(45deg) }
}

.LoadingSpinner_center-spinner__2vNux {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  margin-bottom: 35rem;
}

.LoadingSpinner_ldio-3vi3igwauhw__80mjp > div {
  -webkit-transform-origin: 97px 97px;
          transform-origin: 97px 97px;
  -webkit-animation: LoadingSpinner_ldio-3vi3igwauhw__80mjp 0.2247191011235955s infinite linear;
          animation: LoadingSpinner_ldio-3vi3igwauhw__80mjp 0.2247191011235955s infinite linear;
}
.LoadingSpinner_ldio-3vi3igwauhw__80mjp > div div {
    position: absolute;
    width: 21.34px;
    height: 143.56px;
    background: #454545;
    left: 97px;
    top: 97px;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
}
.LoadingSpinner_ldio-3vi3igwauhw__80mjp > div div:nth-child(1) {
    width: 116.39999999999999px;
    height: 116.39999999999999px;
    border-radius: 50%;
}
.LoadingSpinner_ldio-3vi3igwauhw__80mjp > div div:nth-child(6) {
    width: 77.6px;
    height: 77.6px;
    background: #ffffff;
    border-radius: 50%;
}.LoadingSpinner_ldio-3vi3igwauhw__80mjp > div div:nth-child(3) {
  -webkit-transform: translate(-50%,-50%) rotate(45deg);
          transform: translate(-50%,-50%) rotate(45deg)
}.LoadingSpinner_ldio-3vi3igwauhw__80mjp > div div:nth-child(4) {
  -webkit-transform: translate(-50%,-50%) rotate(90deg);
          transform: translate(-50%,-50%) rotate(90deg)
}.LoadingSpinner_ldio-3vi3igwauhw__80mjp > div div:nth-child(5) {
  -webkit-transform: translate(-50%,-50%) rotate(135deg);
          transform: translate(-50%,-50%) rotate(135deg)
}
.LoadingSpinner_loadingio-spinner-gear-3eru7zplu16__1eVxx {
  width: 194px;
  height: 194px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.LoadingSpinner_ldio-3vi3igwauhw__80mjp {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: translateZ(0) scale(1);
          transform: translateZ(0) scale(1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0; /* see note above */
}
.LoadingSpinner_ldio-3vi3igwauhw__80mjp div { box-sizing: content-box; }
/* generated by https://loading.io/ */

