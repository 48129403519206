@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@500&display=swap");

.nav-links {
  display: none;
}

@media only screen and (min-width: 990px) {
  .nav-links {
    list-style: none;
    padding-left: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .nav-links_auth a {
    background-color: transparent !important;
    color: white !important;
    border: 1pt solid white !important;
    padding: 0 0.25rem !important;
  }

  .nav-links_auth a:hover,
  .nav-links_auth a:active {
    color: #f7e0ad !important;
    border: 1pt solid #f7e0ad !important;
  }

  .nav-links a {
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    margin: 0 0.5rem;
    padding: 0;
    color: white;
    font-size: 15px;
    display: flex;
    justify-content: center;
    text-decoration: none;
  }

  .nav-links a:hover {
    color: #f7e0ad;
  }

  .nav-links button {
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    border: 1pt solid white;
    color: white;
    margin-left: 0.5rem;
    font-size: 15px;
    background-color: transparent;
    padding: 0 0.25rem;
    text-align: center;
  }

  .nav-links button:hover {
    cursor: pointer;
    border: solid 1pt #f7e0ad;
    color: #f7e0ad;
  }
}
