body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slide-in-down-enter {
  transform: translateY(-15%);
}

.slide-in-down-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 300ms;
}

.slide-in-down-exit {
  transform: translateY(0%);
  opacity: 1;
}

.slide-in-down-exit-active {
  transform: translateY(-10%);
  opacity: 0;
  transition: all 300ms;
}


.slide-in-right-enter {
  transform: translateX(15%);
}

.slide-in-right-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-right-exit {
  transform: translateX(0);
  opacity: 1;
}

.slide-in-right-exit-active {
  transform: translateX(15%);
  opacity: 0;
  transition: all 200ms;
}

.modal-transition-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-transition-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 300ms;
}

.modal-transition-exit {
  transform:translateY(0);
  opacity: 1;
}

.modal-transition-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 300ms;
}

.post-transition-enter {
  opacity: 0;
  transform:scaleY(0);
  max-height: 0px;
}

.post-transition-enter-active {
  opacity: 1;
  transform: scaleY(1);
  max-height: 100%;
  transition: all 200ms;
}

.post-transition-exit {
  opacity: 1;
  transform: scaleY(1);
  max-height: 100%
}

.post-transition-exit-active {
  transform: scaleY(0);
  opacity: 0;
  max-height: 0px;
  transition: all 200ms;
}

.slide-down-enter {
  opacity: 0;
  max-height: 0px;
}

.slide-down-enter-active {
  opacity: 1;
  height: auto;
  max-height: 600px;
  transition: all 300ms;
}

.slide-down-exit {
  opacity: 1;
  max-height: 600px;
}

.slide-down-exit-active {
  opacity: 0;
  overflow: hidden;
  max-height: 0px;
  transition: all 300ms;
}


@keyframes slideInRight {
  from {
    transform: translateX(-10rem);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(10rem);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}