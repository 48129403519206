@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@500&display=swap");

.menu-btn {
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
}

.menu-btn span {
  display: block;
  width: inherit;
  height: 2.5px;
  background: white;
}

.main-title {
  text-decoration: none;
  color: #f7e0ad;
  font-family: "Prompt", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.25rem;
}

.main-title:hover {
  color: white;
}

.main-nav {
  display: none;
}

@media only screen and (min-width: 370px) {
  .menu-btn {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media only screen and (min-width: 990px) {
  .menu-btn {
    display: none;
  }

  .main-nav {
    display: block;
    padding-left: 0;
  }
}
