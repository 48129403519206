.drawer-nav-links {
  list-style: none;
  margin: 2rem 1rem;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  row-gap: 20px;
  align-items: center;
}

.drawer-nav-links a {
  margin: 2rem 0;
  color: white;
  font-size: 25px;
  padding: 0.25rem;
  text-decoration: none;
}

.drawer-nav-links a:hover {
  color: #f7e0ad;
}

.drawer-nav-links-auth {
  background-color: #f7e0ad;
  color: #454545 !important;
  padding: 5px 8px;
}

.drawer-nav-links-auth:hover {
  transform: scale(1.05);
}

.drawer-nav-links-auth a {
  color: #454545;
}

.drawer-nav-links-auth a:hover {
  color: #454545;
}

.drawer-nav-links button {
  background-color: #f7e0ad;
  border: none;
  font-size: 25px;
  color: #454545;
  padding: 5px 8px;
}

.drawer-nav-links button:hover {
  transform: scale(1.05);
}

.drawer-nav-links a:hover
.drawer-nav-links a:active,
.active {
  color: #f7e0ad;
}